import LocalStorageService from 'web-app/services/local-storage';
import {hasValue} from 'web-app/util/typescript';

export const DebugLocalStorageFlags = {
    FAMLYTICS: {
        flag: 'FAMLYTICS',
        title: 'Toggle Famlytics Debugging on/off',
        description:
            'Toggle this on, to see all Famlytics events printed to the console. Make sure the feature "ENABLE_ANALYTICS" is enabled as well.',
    },
    SHOW_UPCOMING_FEATURES_SETTING: {
        flag: 'SHOW_UPCOMING_FEATURES_SETTING',
        title: 'Toggle "Upcoming feature" settings section on/off',
        description: 'Toggle this on to show the "Upcoming features" tab on the settings page.',
    },
    ENABLE_INTERCOM_TEST_ENVIRONMENT: {
        flag: 'ENABLE_INTERCOM_TEST_ENVIRONMENT',
        title: 'Connect to Intercom test environment',
        description: 'Connect to Intercom test environment for debugging/dev purposes.',
    },
    SHOW_AUTO_LOGOUT_STATS: {
        flag: 'SHOW_AUTO_LOGOUT_STATS',
        title: 'Auto logout V2 status',
        description:
            'Toggle this to get an overlay that shows all details for auto logout. Auto logout feature and V2 needs to be enabled.',
    },
    EDIT_MODAL_PAYMENT_IMPORT: {
        flag: 'EDIT_MODAL_PAYMENT_IMPORT',
        title: 'Enables editing Ready & Incomplete payment rows in the Payments Import',
        description: 'Toggle this on to see the edit modal when clicking on Incomplete Payment Rows.',
    },
    ENABLE_IMAGICK_IMAGE_PROCESSING: {
        flag: 'ENABLE_IMAGICK_IMAGE_PROCESSING',
        title: 'Enable Imagick image processing',
        description: 'Toggle this on to use Imagick for image processing instead of GD (in core).',
    },
    ENABLE_XERO_PAYMENTS: {
        flag: 'ENABLE_XERO_PAYMENTS',
        title: 'Enables Xero payments functionality',
        description: 'Toggle this on to see the Xero payments article link',
    },
    TRACKING_VISUALIZATION: {
        flag: 'TRACKING_VISUALIZATION',
        title: 'Enable tracking visualization',
        description:
            'You can see tracking events for elements, see searchable live feed of events, etc. Make sure the feature "ENABLE_ANALYTICS" is enabled as well.',
    },
    SHOW_DEFAULT_FTE: {
        flag: 'SHOW_DEFAULT_FTE',
        title: 'Show default fte',
        description: 'Toggle this to show default fte.',
    },
    ENABLE_FORM_BUILDER_DEBUG: {
        flag: 'ENABLE_FORM_BUILDER_DEBUG',
        title: 'Enable form builder debug info',
        description: 'Toggle this on to access the form builder debug info',
    },
    FORM_BUILDER_DEBUG_INFO: {
        flag: 'FORM_BUILDER_DEBUG_INFO',
        title: 'Adds debug info to the form builder state (for e2e)',
        description: 'Toggle this to add debug info to the form builder state (for e2e)',
    },
    SHOW_FINANCE_DEBUGGING_BUTTON: {
        flag: 'SHOW_FINANCE_DEBUGGING_BUTTON',
        title: 'Show finance debugging button',
        description: 'Show finance debugging button to access Finance debugging page.',
    },
    SHOW_BULK_PLAN_DELETE: {
        flag: 'SHOW_BULK_PLAN_DELETE',
        title: 'Show Bulk Plan delete option',
        description: 'Toggle this to show the bulk delete latest plan in plans page',
    },
    TRACE_ALL_TRANSACTIONS: {
        flag: 'TRACE_ALL_TRANSACTIONS',
        title: '[Sentry] Trace all transactions',
        description: 'Turn this on to have all transactions traced in Sentry.',
    },
    SENTRY_ENABLE_REPLAY: {
        flag: 'ENABLE_REPLAY',
        title: '[Sentry] Enable Replay',
        description: 'Turn this on to enable Sentry replay.',
    },
    SENTRY_DIAGNOSTIC_MODE: {
        flag: 'SENTRY_DIAGNOSTIC_MODE',
        title: '[Sentry] Diagnostic mode',
        description:
            "Turn this on to enable Sentry diagnostic mode. This will bypass the filtering we've setup in sentry-setup.ts.",
    },
    ENABLE_SCS_ONBOARDING: {
        flag: 'ENABLE_SCS_ONBOARDING',
        title: 'Enables Sales-to-CS onboarding flow',
    },
    SHOW_BULK_BILLING_PROFILE_UPDATE: {
        flag: 'SHOW_BULK_BILLING_PROFILE_UPDATE',
        title: 'Show Bulk Billing Profile Update in the Plans page',
        description: 'Used to handle bulk billing profile update in the Plans page',
    },
    FAMLY_HOME_CATEGORY_ONBOARDING: {
        flag: 'FAMLY_HOME_CATEGORY_ONBOARDING',
        title: 'Show Category Onboarding widget in Famly Home',
        description: 'Used to display the work in progress widget that will be in Famly Home',
    },
    FINANCE_POWER_PAGE: {
        flag: 'FINANCE_POWER_PAGE',
        title: 'Use the new finance power page',
        description: 'Use the new finance power page instead of finance overview',
    },
    MOBILE_APP_PACKAGE_PURCHASE_MAGIC_LINK_REDIRECT: {
        flag: 'MOBILE_APP_PACKAGE_PURCHASE_MAGIC_LINK_REDIRECT',
        title: 'When trying to purchase a package on the mobile app, redirect the user to the browser with a magic link',
    },
    MEAL_PLANS_DND: {
        flag: 'MEAL_PLANS_DND',
        title: 'Enable dnd of food items in meal plans',
    },
    BULK_ADD_ATTENDANCE: {
        flag: 'BULK_ADD_ATTENDANCE',
        title: 'Show the bulk add attendance modal',
    },
    INQUIRIES_V2: {
        flag: 'INQUIRIES_V2',
        title: 'Enable this to try out org level inquiries',
    },
    SHOW_STAT_FORM_DEBUG: {
        flag: 'SHOW_STAT_FORM_DEBUG',
        title: 'Enable stat form debug information',
    },
    SMS_NOTIFICATIONS: {
        flag: 'SMS_NOTIFICATIONS',
        title: 'Enable SMS notifications in Newsfeed and Messages',
    },
    SHOW_PURCHASES_NEW_DESIGN_ON_MOBILE: {
        flag: 'SHOW_PURCHASES_NEW_DESIGN_ON_MOBILE',
        title: 'Show new design for purchases on mobile and automatic pricing',
    },
    MF_LISTS_MANAGE_CHILDREN: {
        flag: 'MF_LISTS_MANAGE_CHILDREN',
        title: 'Enable manage children MF List implementation',
    },
    NEW_REPORTS_OVERVIEW: {
        flag: 'NEW_REPORTS_OVERVIEW',
        title: 'Enable new reports overview',
    },
    NEW_REPORT_CREATE_FLOW: {
        flag: 'NEW_REPORT_CREATE_FLOW',
        title: 'Enable the new "Create report" flow on the new reports overview. Has no effect if NEW_REPORTS_OVERVIEW is not enabled.',
    },
    NEW_OCCUPANCY_REPORT: {
        flag: 'NEW_OCCUPANCY_REPORT',
        title: 'Enable new occupancy report',
    },
    PRINT_CHILD_PROFILE: {
        flag: 'PRINT_CHILD_PROFILE',
        title: 'Enable child profile printing',
    },
    PLAN_RULES: {
        flag: 'PLAN_RULES',
        title: 'Enable plan rules',
    },
    SIMPLIFIED_US_BILLING_PLAN: {
        flag: 'SIMPLIFIED_US_BILLING_PLAN',
        title: 'Enable simplified US billing plan',
    },
    CHILD_PROFILE_COLLECTION_PASSWORD: {
        flag: 'CHILD_PROFILE_COLLECTION_PASSWORD',
        title: 'Enable child collection passwords',
    },
    TAX_STATEMENT_READY_EMAIL_BUTTON: {
        flag: 'TAX_STATEMENT_READY_EMAIL_BUTTON',
        title: 'Enable tax statement ready email button',
    },
    SIDEKICK_ACTIVITY_GENERATOR: {
        flag: 'SIDEKICK_ACTIVITY_GENERATOR',
        title: 'Enable the Sidekick activity generator',
    },
    STAFF_LEAVE_2: {
        flag: 'STAFF_LEAVE_V2',
        title: 'Enable Staff Leave 2.0',
    },
} satisfies {
    [flagId: string]: {
        flag: string;
        title: string;
        description?: string;
    };
};

/**
 * I grew tired of having to import DebugLocalStorageFlags to check if a flag is enabled
 * so I added an overload that allows you to simply look up by the union of keys in DebugLocalStorageFlags
 */
interface CheckIfFlagIsToggled {
    (flag: string): boolean;
    (flag: keyof typeof DebugLocalStorageFlags): boolean;
}
export const checkIfFlagIsToggled: CheckIfFlagIsToggled = flag => {
    const lookup = DebugLocalStorageFlags[flag];
    if (hasValue(lookup)) {
        return Boolean(LocalStorageService.getFromLocalStorage(lookup.flag));
    }
    return Boolean(LocalStorageService.getFromLocalStorage(flag));
};
